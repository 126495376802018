<template>
  <modal v-loading="loaders.supportServiceCategory" :show.sync="$store.state.modals.addSupportServiceCategory">
    <h1 slot="header" class="modal-title pt-2" id="modal-title-default">Add Support Service Category</h1>
    <support-service-category-form
      @storeSupportServiceCategory="storeSupportServiceCategory"
      ref="supportServiceCategoryForm">
    </support-service-category-form>
  </modal>
</template>

<script>

import SupportServiceCategoryForm from "@/views/Pages/ContentSection/SupportService/SupportServiceCategory/SupportServiceCategoryForm";

export default {
  name: "AddSupportServiceCategoryModal",
  components: {
    SupportServiceCategoryForm
  },
  data() {
    return {
      loaders: {
        supportServiceCategory: false
      }
    }
  },
  methods: {

    /**
     * Store a new support service category
     * @param {event} event - Event object
     */
    storeSupportServiceCategory(event) {
      let vm = this
      vm.loaders.supportServiceCategory =true
      const formData = {}
      Object.assign(formData,event)
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      try {
        vm.$store.dispatch('_storeSupportServiceCategory', {config, formData})
        vm.$notify.success({
          title: 'Success',
          message: 'Support Service Category created successfully.'
        })
        this.$refs.supportServiceCategoryForm.resetForm()
        vm.$store.commit('hideAddSupportServiceCategoryModal')
        vm.$emit('updateSupportServiceCategories')
      }
      catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Add Support Service Category',
          message: message
        })
      }
      vm.loaders.supportServiceCategory = false
    }
  }
}
</script>

<style scoped>

</style>
