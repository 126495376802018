<template>
  <div>
    <validation-observer ref="supportServiceCategoryForm" v-slot="{handleSubmit}">
      <form class="needs-validation" @submit.prevent="handleSubmit(submitForm)">
        <base-input
          type="text"
          :rules="{required: true}"
          name="name"
          v-model="request.supportServiceCategoryForm.name"
          label="Category Name*"
          placeholder="Name">
        </base-input>
        <base-input name="status" label="Status*" :rules="{required: true}">
          <el-select
            class="w-100"
            label="Status*"
            v-model="request.supportServiceCategoryForm.status">
            <el-option
              v-for="option in dropdowns.status"
              class="select-danger"
              :value="option.value"
              :label="option.label"
              :key="option.label">
            </el-option>
          </el-select>
        </base-input>
        <div class="float-right mt-4">
          <base-button type="secondary" class="ml-auto" @click="hideForm">
            Cancel
          </base-button>
          <base-button native-type="submit" type="submit" class="btn-primary-action">
            Save
          </base-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: "SupportServiceCategoryForm",
  props: ['supportServiceCategoryDataToEdit'],
  data() {
    return {
      dropdowns: {
        status: [{
          value: 1,
          label: 'Active'
        },
          {
            value: 0,
            label: 'Inactive',
          },
        ]
      },
      request: {
        supportServiceCategoryForm: {
          name: '',
          status: ''
        }
      }
    }
  },
  watch: {
    /**
     * Save new value to supportServiceCategoryDataToEdit property
     * @param newVal - New Value of supportServiceCategoryDataToEdit property
     */
    supportServiceCategoryDataToEdit: function (newVal) {
      this.request.supportServiceCategoryForm.name = newVal.name ?? ''
      this.request.supportServiceCategoryForm.status = newVal.status ?? ''
    }
  },
  methods: {

    /**
     * Validates the form field, submit it and emit relevant function
     */
    async submitForm() {
      let vm = this
      const isSupportServiceCategoryFormValid = await this.$refs['supportServiceCategoryForm'].validate();
      if (isSupportServiceCategoryFormValid) {
        if(this.$store.state.modals.addSupportServiceCategory) {
          vm.$emit('storeSupportServiceCategory', vm.request.supportServiceCategoryForm)
        } else {
          vm.$emit('updateSupportServiceCategory', vm.request.supportServiceCategoryForm)
        }
      }
      else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        });
      }
    },

    /**
     * Hide the form
     */
    hideForm() {
      if(this.$store.state.modals.addSupportServiceCategory) {
        this.resetForm()
        this.$store.commit('hideAddSupportServiceCategoryModal')
      } else {
        this.$store.commit('hideEditSupportServiceCategoryModal')
      }
    },

    /**
     * Reset the Support service category form fields
     */
    resetForm(){
      this.request.supportServiceCategoryForm.name = ''
      this.request.supportServiceCategoryForm.status = ''
      this.$refs.supportServiceCategoryForm.reset();
    }
  }
}
</script>

<style scoped>

</style>
