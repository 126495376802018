<template>
  <modal v-loading="loaders.resource" :show.sync="$store.state.modals.editSupportServiceCategory">
    <h1 slot="header" class="modal-title pt-2" id="modal-title-default">Edit Support Service Category</h1>
    <support-service-category-form
      :support-service-category-data-to-edit="response.supportServiceCategory"
      @updateSupportServiceCategory="updateSupportServiceCategory">
    </support-service-category-form>
  </modal>
</template>

<script>

import SupportServiceCategoryForm from "@/views/Pages/ContentSection/SupportService/SupportServiceCategory/SupportServiceCategoryForm";

export default {
  name: "EditSupportServiceCategoryModal",
  props: ['id'],
  components: {
    SupportServiceCategoryForm
  },
  data() {
    return {
      editCategoryId: '',
      response: {
        supportServiceCategory: ''
      },
      loaders: {
        resource: false
      }
    }
  },
  watch: {
    /**
     * Watcher to check newVal of Id prop
     * @param {number} newVal - New Value of the Id prop
     */
    id: function (newVal) {
      if (newVal != null) {
        this.editCategoryId = newVal
        this.getSupportServiceCategory()
      }
    }
  },
  methods: {

    /**
     * Fetch the relevant support service category
     */
    async getSupportServiceCategory() {
      let vm = this
      vm.loaders.resource = true
      const payload = { id: vm.editCategoryId }
      try {
        const response = await vm.$store.dispatch('_getSupportServiceCategory', payload)
        vm.response.supportServiceCategory = response.data.data
      }
      catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Edit Support Service Category',
          message: message
        })
      }
      vm.loaders.resource = false
    },

    /**
     * Update the support service category
     * @param {event} event - Event object
     */
    updateSupportServiceCategory(event) {
      let vm = this
      vm.loaders.resource =true
      const formData = {}
      Object.assign(formData,event)
      formData.id = vm.editCategoryId
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      try {
        vm.$store.dispatch('_updateSupportServiceCategory', {config, formData})
        vm.$notify.success({
          title: 'Success',
          message: 'Support Service Category updated successfully.'
        })
        vm.$store.commit('hideEditSupportServiceCategoryModal')
        vm.$emit('updateSupportServiceCategories')
      }
      catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Edit Support Service Category',
          message: message
        })
      }
      vm.loaders.resource = false
    }
  }
}
</script>

<style scoped>

</style>
